@import "../../../baseTheme/settings";

.third {
    &--party-script {
        padding-top: 62px;
        @include media-breakpoint-up(md) {
            padding-top: 72px;
        }
        .heading {
            @include media-breakpoint-down(md) {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
            }
            h2 {
                font-size: 48px;
                line-height: 48px;
                letter-spacing: -0.06em;
    
                @include media-breakpoint-down(md) {
                    font-size: 30px;
                    line-height: 32px;
                    margin-bottom: 8px;
                }
            }
    
            p {
                font-size: 16px;
                margin: 0;
                line-height: 130%;
                letter-spacing: -0.32px;
            }
        }
        .subHeading {
            text-align: left;
            @include media-breakpoint-down(md) {
                width: 100%;
                text-align: center;
            }
            p {
                margin: 0;
                display: inline-block;
                vertical-align: top;
                text-decoration: underline;
                text-decoration-thickness: 1.2px;
                text-decoration-color:$plum !important;
                a {
                    color: $plum !important;
                    font-family: var(--skin-body-font);
                    text-decoration: none;
                }
                img:before {
                    content: none;
                }
            }
        }
    }

}